var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "pull-request",
      class: {
        [`${_vm.pull_request.build.status.toLowerCase()}`]: true,
        selected: _vm.selected,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.$emit("click", _vm.pull_request.id)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("commit-build-status", {
            class: _vm.pull_request.build.status.toLowerCase(),
            attrs: {
              pull_request: _vm.pull_request_name,
              build_status: _vm.pull_request.build.status,
              build_number: _vm.pull_request.build.number,
            },
          }),
        ],
        1
      ),
      _c("el-form", { attrs: { inline: "" } }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "sub-content" }, [
              _c(
                "div",
                { staticClass: "branches" },
                [
                  _c("el-tag", { attrs: { size: "mini", type: "info" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.pull_request.source.branch.name) + " "
                    ),
                  ]),
                  _c("i", { staticClass: "el-icon-right" }),
                  _c("el-tag", { attrs: { size: "mini", type: "info" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.pull_request.destination.branch.name) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tag" },
                [
                  _vm.pr_tags.length > 0
                    ? _vm._l(_vm.pr_tags, function (tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag.id,
                            attrs: { size: "mini", type: "primary" },
                          },
                          [
                            _c("i", { staticClass: "el-icon-price-tag" }),
                            _vm._v(" " + _vm._s(tag.name) + " "),
                          ]
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "date" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("timeAgo")(new Date(_vm.pull_request.updated_on))
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "commit-title" }, [
              _vm.selected
                ? _c(
                    "div",
                    { staticClass: "hash" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Title" } },
                        [
                          _c("el-input", {
                            attrs: {
                              readonly: !_vm.creating_release,
                              size: "mini",
                              "inline-message": "",
                            },
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Version" } },
                        [
                          _c("el-input", {
                            attrs: {
                              readonly: !_vm.creating_release,
                              size: "mini",
                              "inline-message": "",
                            },
                            model: {
                              value: _vm.form.version,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "version", $$v)
                              },
                              expression: "form.version",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selected && !_vm.pr_release
                ? _c(
                    "div",
                    [
                      _vm.creating_release
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.release },
                              },
                              [_vm._v(" Release ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.cancelRelease },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ]
                        : _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.createRelease },
                            },
                            [_vm._v(" Create release ")]
                          ),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm.selected
              ? [
                  _c("text-editor", {
                    ref: "editor",
                    staticClass: "detail",
                    attrs: {
                      editable: _vm.creating_release,
                      content: _vm.form.content,
                    },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }