var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.module
    ? _c(
        "div",
        [
          _c(
            "actions-buttons",
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "Releases", name: "_releases" },
                  }),
                  _vm._l(_vm.environments, function (env) {
                    return _c("el-tab-pane", {
                      key: env.name,
                      attrs: { name: `_env_${env.name}`, label: env.name },
                    })
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "Build Tree", name: "_buildtree" },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "slide", mode: "out-in" } },
            [
              _vm.activeTab == "_releases"
                ? _c("module-tab-releases", {
                    attrs: { releases: _vm.releases },
                  })
                : _vm.env_from_active_tab
                ? _c("module-tab-environment", {
                    attrs: {
                      environment: _vm.env_from_active_tab,
                      module: _vm.module,
                      pull_requests: _vm.pullRequestsForEnvironment(
                        _vm.env_from_active_tab
                      ),
                      releases: _vm.releases,
                    },
                    on: {
                      "release:create": (data) =>
                        _vm.createRelease(_vm.env_from_active_tab, data),
                    },
                  })
                : _vm._e(),
              _vm.activeTab == "_buildtree"
                ? _c("module-tab-buildtree", {
                    attrs: {
                      environments: _vm.environments,
                      related_pull_requests: _vm.related_pull_requests,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }