var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.related_pull_requests, function (branch, idx) {
      return _c("repo-tree-node", {
        key: idx,
        attrs: {
          show_header: idx === 0,
          node: branch,
          environments: _vm.environments,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }