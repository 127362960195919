var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Releases", no_background: "" } },
    [
      _vm.sorted_releases.length
        ? _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.sorted_releases, function (release) {
              return _c("module-release-block", {
                key: release.id,
                staticClass: "release",
                attrs: { release: release },
              })
            }),
            1
          )
        : _c("span", { staticClass: "no-releases" }, [_vm._v("No Releases")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }