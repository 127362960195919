var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "container", on: { click: _vm.handleClick } },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "el-tag",
            {
              staticClass: "ref",
              attrs: {
                type: "info",
                "disable-transitions": true,
                size: "small",
              },
            },
            [_vm._v(" " + _vm._s(_vm.release.version) + " ")]
          ),
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.release.note.title)),
          ]),
          _c("span", { staticClass: "date" }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("timeAgo")(_vm.release.created_at.toDate())) +
                " "
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }