var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "build" }, [
    _vm.pull_request
      ? _c("span", { staticClass: "pull-request" }, [
          _vm._v(_vm._s(_vm.pull_request)),
        ])
      : _vm._e(),
    _vm.build_number
      ? _c("span", [_vm._v("Build #" + _vm._s(_vm.build_number))])
      : _vm._e(),
    _c("span", { staticClass: "status" }, [_vm._v(_vm._s(_vm.build_status))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }