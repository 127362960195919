var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Pull requests", no_background: "" } },
    [
      _vm.pull_requests.length
        ? _c(
            "ul",
            { staticClass: "list" },
            _vm._l(_vm.pull_requests, function (pull_request) {
              return _c("pull-request-block", {
                key: pull_request.id,
                attrs: {
                  selected: pull_request.id === _vm.active_pull_request,
                  module: _vm.module,
                  pull_request: pull_request,
                  releases: _vm.releases,
                  tags: _vm.tags,
                },
                on: {
                  click: _vm.showDetail,
                  "release:create": (data) => _vm.$emit("release:create", data),
                },
              })
            }),
            1
          )
        : _c("span", { staticClass: "no-releases" }, [
            _vm._v("No Pull requests"),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }