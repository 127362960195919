var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.show_header
      ? _c(
          "div",
          { staticClass: "header" },
          _vm._l(_vm.environments, function (env) {
            return _c("div", { key: env.name, staticClass: "env" }, [
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(env.name))]),
            ])
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "all", class: { release: _vm.is_release } },
      _vm._l(_vm.node, function (leaf, idx) {
        return _c(
          "div",
          {
            key: leaf ? `${leaf.env}-${leaf.id}` : idx,
            staticClass: "leaf",
            class: {
              "has-left": _vm.hasLeft(idx),
              "has-right": _vm.hasRight(idx),
            },
          },
          [
            _c("div", {
              staticClass: "left",
              class: { master: _vm.isMasterLeft(idx) },
            }),
            leaf
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "name",
                      class: { [_vm.buildClass(leaf)]: true },
                    },
                    [
                      leaf.build && leaf.build.number
                        ? _c("span", [
                            _vm._v(" #" + _vm._s(leaf.build.number) + " "),
                          ])
                        : _c("span", [_vm._v(_vm._s(leaf.id))]),
                    ]
                  ),
                  _vm.active_count === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "build-info inline",
                          class: {
                            [_vm.buildClass(leaf)]: true,
                            release: _vm.is_release,
                          },
                        },
                        [
                          _c("div", { staticClass: "left" }),
                          _c("div", { staticClass: "content" }, [
                            _vm.is_release
                              ? _c("div", { staticClass: "version" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.parent.release.version) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.title)),
                            ]),
                          ]),
                          _c("div", { staticClass: "right" }),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "right",
              class: { master: _vm.isMasterRight(idx) },
            }),
          ]
        )
      }),
      0
    ),
    _vm.active_count > 1
      ? _c(
          "div",
          { staticClass: "build-info", class: { release: _vm.is_release } },
          [
            _c("div", { staticClass: "top line" }),
            _c("div", { staticClass: "content" }, [
              _vm.is_release
                ? _c("div", { staticClass: "version" }, [
                    _vm._v(" " + _vm._s(_vm.parent.release.version) + " "),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }